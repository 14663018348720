import React from 'react';

import Typography from '@material-ui/core/Typography';
import MaterialUILayout from '../components/material-ui-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <MaterialUILayout>
      <StaticImage
        alt="Rameswaram"
        src="../images/rameswaram-1.jpg"
        style={{ margin: '2rem 1rem', maxWidth: '95vw' }}
      />
      <div className={classes.container}>
        <Typography variant="h1" className={classes.mainTitle}>
          Masumi Hayashi (1945-2006):
        </Typography>
        <Typography variant="h2" className={classes.subTitle}>
          An Odyssey Through Panoramic Photo Collage
        </Typography>

        <Typography variant="body1">
          Welcome to the Masumi Hayashi Foundation, dedicated to preserving and
          elevating the artistic legacy of Dr. Masumi Hayashi. Our mission is to
          celebrate and share her unique contribution to the world of art: the
          creation of visionary panoramic photo collage that captivate and
          engage the viewer.
        </Typography>

        <Typography variant="body1">
          Dr. Hayashi's creative process was extraordinary. Using her trusted
          Nikon 35mm film camera, equipped only with an internal light meter,
          she manually tuned each exposure's f-stop, shutter speed, and
          aperture. This meticulous approach, enjoyed none of the immediate
          feedback provided by today's digital technologies. Instead she
          employed her masterful understanding of the photographic process and
          exercised her genius ability to visualize the final composition in
          selecting a location to set up and take the hundreds of individual
          exposures that make up each piece.
        </Typography>

        <Typography variant="body1">
          Dr. Hayashi's unique method of capturing her photos involved setting
          up her camera on a tripod and moving it in increments, capturing
          hundreds of different angles and perspectives over several hours. This
          process allowed her to capture multiple slices of time and space,
          rather than a single moment.
        </Typography>

        <Typography variant="body1">
          Unlike the fleeting, screen-bound images of the digital age, Dr.
          Hayashi's large-scale photo collages are physical objects that warp
          time and space. They demand in-person viewing for full appreciation,
          as they cannot be fully understood in a single glance. Composed of
          individual snapshot-sized photos, Hayashi created monumental works
          that reflect societal memory and disrupt the logic of documentary
          photos and space.
        </Typography>

        <Typography variant="body1">
          The final result is a work that requires the viewer to actively engage
          with it, piecing together the individual components to understand the
          whole. The viewer is made aware of the individual components and the
          fact that they have been assembled to create the whole. This
          interaction is a testament to Dr. Hayashi's exceptional vision and
          evolving spirit, and it is reflected in her timeless works of art,
          many of which are now housed in prestigious art museum collections
          worldwide, including the Smithsonian Museum of Asian Art and the San
          Francisco Museum of Modern Art (SF MOMA).
        </Typography>

        <Typography variant="body1">
          This digital platform, powered by Gatsby 3 and featuring Material UI
          components, enables an interactive engagement with Dr. Hayashi's work.
          Users can zoom in and out on artwork details and the browser will use
          larger or smaller image files to support the view. Keyboard support
          enables quick access to artwork links by using 'tab', 'shift', and
          'enter' to quickly discover and select additional works to view. Press
          'escape' or click outside the drawer to close the navigation drawer.
        </Typography>
      </div>
    </MaterialUILayout>
  );
};

export default Home;
